import SlideAndBounce from "@/components/animations/slide-and-bounce";
import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import StickyScrollRevealDemo from "./StickyScrollRevealDemo";

function HowItWorks() {
  return (
    <>
      <SlideAndBounce>
        <article className="flex flex-col items-start mb-5">
          {/* <div className="flex"> */}
          {/* <DescriptionHighlight /> */}
          <TypographyP className="text-primary text-[18px] font-semibold">
            Our Process
          </TypographyP>
          <TypographyH2 className="!text-[30px] font-semibold">
            How It Works
          </TypographyH2>
          {/* </div> */}
        </article>
        <StickyScrollRevealDemo />
      </SlideAndBounce>
    </>
  );
}

export default HowItWorks;
