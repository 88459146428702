import toastService from "@/services/toastService";
import { Axios } from "axios";

export const setupResponseInterceptors = (axiosInstance: Axios) => {
  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Handle error responses here (e.g., redirect to login on 401)
      // if (error.res.ponse && error.response.statusCode === 401) {
      // Handle unauthorized errors (e.g., log out the user)
      // }
      console.log("intercepted error", error, error.response.data.message);
      toastService.error(error?.response?.data?.message);
      return Promise.reject(error);
    }
  );
};
