import "./App.css";
import { Provider } from "react-redux";
import { store } from "@/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Header } from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import { ThemeProvider } from "@/contexts/ThemeProvider";
import { Outlet, useLocation } from "react-router-dom";
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingProvider } from "@/contexts/LoadingContext";
import Loader from "@/components/Loader";
import packagejson from "../package.json";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { useEffect } from "react";
import { onMessageListener, requestForToken } from "./firebase";
import { CustomProvider as RsProvider } from "rsuite";

function App() {
  console.log("-------- app version --------", packagejson.version);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8fdda3", // Change primary color
      },
      secondary: {
        main: "#7dce93", // Change secondary color
      },
      // You can customize other colors as needed
      background: {
        default: "#f5f5f5", // Change default background color
      },
    },
    typography: {
      fontFamily: '"Outfit", "Arial", "sans-serif"',
      fontSize: 14,
    },
  });
  const location = useLocation();

  useEffect(() => {
    requestForToken();
  }, []);

  // Handle incoming messages
  onMessageListener()
    .then((payload) => {
      console.log("Message received. ", payload);
      alert(`New notification: ${payload.notification?.title}`);
    })
    .catch((err) => console.log("Failed to receive message: ", err));

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <RsProvider theme="light">
            <MUIThemeProvider theme={theme}>
              <LoadingProvider>
                <Loader />
                <ScrollArea className="h-full">
                  {location.pathname != "/explore" && <Header />}
                  <Outlet />
                  {location.pathname != "/explore" && <Footer />}
                  <ToastContainer />
                  <ScrollBar />
                </ScrollArea>
              </LoadingProvider>
              q
            </MUIThemeProvider>
          </RsProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
