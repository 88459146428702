"use client";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

interface SlideAndBounceProps {
  duration?: number;
  children: React.ReactNode;
}

const SlideAndBounce: React.FC<SlideAndBounceProps> = ({
  duration = 2,
  children,
}) => {
  const variants = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: [0, -30, 10, 0], // Slide up and then bounce
      opacity: 1,
    },
  };

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it becomes visible
        }
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect(); // Cleanup on unmount
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: duration }} // times: [0, 0.3, 0.6, 1]
    >
      {children}
    </motion.div>
  );
};

export default SlideAndBounce;
