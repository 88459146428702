export const LOCALIZATION_KEYS = {
  SCREENS: {
    LOGIN_SCREEN: "Login Screen",
    HOME_SCREEN: "Home Screen",
    EXPLORE_SCREEN: "Explore Screen",
    CART_SCREEN: "Cart Screen",
    ACCOUNT_SCREEN: "Account Screen",
  },
  BUTTON_TEXTS: {
    MOVE_TO_HOME: "Move To Home Screen",
  },
  LOGIN_WITH_GOOGLE: "Login with google",
  LOGIN_WITH_FB: "Login with fb",
  LOGIN_WITH_APPLE: "Login with apple",
  APP_AUTHOR: "author",
  APP_AUTHOR_RIGHTS: "All rights reserved.",
  NAV_LINKS: {
    EXPLORE: "Explore",
    FIND_PROS: "Find Pros",
    HOW_IT_WORKS: "How it works",
    PRICING: "Pricing",
    BLOG: "Blog",
    LOGIN: "Login",
    SIGN_UP: "Sign Up",
  },
  FOOTER: {
    FEATURES: "Features",
    FIND_SUBCONTRACTORS: "Find Subcontractors",
    FIND_PROJECTS: "Find Projects",
    ROI_CALCULATOR: "ROI Calculator",
    LEARN: "Learn",
    FEATURE_GOES_HERE: "Feature goes here",
    PROPERTY_TYPES: "Property Types",
    RESIDENTIAL_PROPERTY: "Residential Property",
    COMMERCIAL_PROPERTY: "Commercial Property",
    SOCIETY_PROPERTY: "Society Property",
    OFFICE_PROPERTY: "Office Property",
    STORE_PROPERTY: "Store Property",
    RESOURCES: "Resources",
    EXPLORE: "Explore",
    HOW_IT_WORKS: "How it works",
    PRICING: "Pricing",
    BLOG: "Blog",
    FAQ: "FAQ",
    QUICK_LINKS: "Quick Links",
    SIGN_UP: "Sign Up",
    LOGIN: "Login",
    TERMS_AND_CONDITION: "Terms and Condition",
    PRIVACY_POLICY: "Privacy Policy",
  },
};
