"use client";
import { StickyScroll } from "@/components/ui/sticky-scroll-reveal";
import discoverImage from "@/assets/home/discover-and-win.svg";
import salesImg from "@/assets/home/sales-assistant.svg";
import leadsImg from "@/assets/home/smart-lead-recommedation.webp";
import automatedFlowImage from "@/assets/home/automated-workflows.svg";

const content = [
  {
    title: "Find Opportunities",
    description:
      "Use BuildMapper’s vast database of construction permits and builder profiles to discover the best project leads.",
    content: (
      // <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
      //   Collaborative Editing
      // </div>
      <div className="h-full w-full  flex items-center justify-center text-white">
        <img
          src={leadsImg}
          className="object-contain w-fit"
          alt="linear board demo"
        />
      </div>
    ),
  },
  {
    title: "Build Relationships",
    description:
      "Nurture and manage those leads with our intuitive CRM and personalized communication tools.",
    content: (
      <div className="h-full w-full  flex items-center justify-center text-white">
        <img
          src={discoverImage}
          width={769}
          height={560}
          className="object-contain w-full h-full"
          alt="linear board demo"
        />
      </div>
    ),
  },
  {
    title: "Seal the Deal",
    description:
      "Easily send out quotes, contracts, and invoices directly from the platform—closing deals has never been this simple.",
    content: (
      // <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--orange-500),var(--yellow-500))] flex items-center justify-center text-white">
      //   Seal the deal image
      // </div>
      <div className="h-full w-full  flex items-center justify-center text-white">
        <img
          src={automatedFlowImage}
          className="object-contain w-fit"
          alt="linear board demo"
        />
      </div>
    ),
  },
  {
    title: "Track Performance",
    description:
      "Keep an eye on every part of your business with real-time analytics, so you always know where you stand.",
    content: (
      // <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
      //   Track performance image
      // </div>
      <div className="h-full w-full  flex items-center justify-center text-white">
        <img
          src={salesImg}
          className="object-contain w-fit"
          alt="linear board demo"
        />
      </div>
    ),
  },
];
export default function StickyScrollRevealDemo() {
  return (
    <div className="w-full">
      <StickyScroll content={content} />
    </div>
  );
}
