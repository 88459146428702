import React from "react";
import { Input } from "@/components/ui/input";
import { FaSearch } from "react-icons/fa";
import GradientButton from "./gradient-button";

const SearchBar: React.FC = () => {
  return (
    <div className="bg-white p-2 ps-6 rounded-lg flex items-center space-x-4 shadow-lg w-[30%] max-w-5xl h-[65px]">
      {/* Input Fields */}
      <div className="flex items-center justify-start space-x-4 flex-1">
        <div className="flex-1">
          <label className="block text-charcoal text-start font-bold text-[14px]">
            Find Projects in Your Area
          </label>

          <Input
            placeholder="Enter a City or Country"
            className="p-0 border-none focus-visible:ring-0 focus-visible:ring-offset-0 w-full text-[14px] h-[30px]"
          />
        </div>
      </div>

      {/* Search Button */}
      <GradientButton className=" text-white w-[48px] h-[48px]">
        <FaSearch size={24} />
      </GradientButton>
    </div>
  );
};

export default SearchBar;
