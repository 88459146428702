import heroImage from "@/assets/home/hero-image.webp";
import TypographyH1 from "@/components/typography/TypographyH1";
import { TypographyP } from "../../../components/typography/TypographyP";
import SearchBar from "../../../components/ui/search-bar";
import { TextGenerateEffect } from "@/components/ui/text-generate-effect";
import SlideIn from "@/components/animations/slide-in";
import SlideAndBounce from "@/components/animations/slide-and-bounce";

function HeroSection() {
  const figureStyle = "text-[48px] font-extrabold";

  const dealsStyle =
    "text-[#161c2d] opacity-70 text-[16px] w-[80px] text-start font-bold leading-[24px]";

  const homeGradientBG = "bg-gradient-to-t from-[#eefff2] to-[#fff]";

  return (
    <section className="h-[100vh]">
      {/* heroo with bg image  */}
      <article
        className="w-full h-[75vh] ps-[120px] flex flex-col gap-4 items-start justify-center"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundPositionY: "bottom",
        }}
      >
        <SlideIn>
          <div className="flex flex-col justify-start gap-6 w-[600px]">
            <TypographyH1 className="text-charcoal font-bold text-start leading-[60px]">
              The All-In-One Construction Sales Platform
            </TypographyH1>
          </div>
        </SlideIn>

        <TextGenerateEffect
          duration={1}
          words="We help construction companies build a world-class sales process."
        />
        <SearchBar />
      </article>

      {/* hero figures and numbers */}
      <SlideAndBounce>
        <article
          className={`grid grid-cols-2 md:grid-cols-4 p-5 px-8 h-[20vh] ${homeGradientBG}`}
        >
          <div className="flex items-center justify-center gap-2">
            <TypographyP className={figureStyle}>
              $8<span className="text-bmpGreen-500 font-semibold">M</span>
            </TypographyP>
            <TypographyP className={dealsStyle}>Closed Deals</TypographyP>
          </div>
          <div className="flex items-center justify-center gap-2">
            <TypographyP className={figureStyle}>
              40
              <span className="text-bmpGreen-500 font-semibold">
                K<span className="text-[44px] align-bottom">+</span>{" "}
              </span>
            </TypographyP>
            <TypographyP className={dealsStyle}>Projects Listed</TypographyP>
          </div>
          <div className="flex items-center justify-center gap-2">
            <TypographyP className={figureStyle}>
              5
              <span className="text-bmpGreen-500 font-semibold">
                K<span className="text-[44px] align-bottom">+</span>{" "}
              </span>
            </TypographyP>
            <TypographyP className={dealsStyle}>Industry Users</TypographyP>
          </div>
          <div className="flex items-center justify-center gap-2">
            <TypographyP className={figureStyle}>
              10
              <span className="text-bmpGreen-500 font-semibold">
                X<span className="text-[44px] align-bottom">+</span>{" "}
              </span>
            </TypographyP>
            <TypographyP className={dealsStyle}>Revenue Growth</TypographyP>
          </div>
          {/* <div className="flex items-center justify-center gap-2">
          <img src={salesSpark} alt="Sales Spark" />
        </div> */}
        </article>
      </SlideAndBounce>
    </section>
  );
}

export default HeroSection;
