import React, { useMemo } from "react";
import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import featureImage from "@/assets/home/overview.webp";
import { TypographyH3 } from "@/components/typography/TypographyH3";
import { HeroHighlight, Highlight } from "@/components/ui/hero-highlight";
import { motion } from "framer-motion";
import SlideIn from "@/components/animations/slide-in";
import SlideAndBounce from "@/components/animations/slide-and-bounce";
import LottieAnimation from "@/components/animations/lottie-animation";
import bulletIcon from "../../../assets/home/sales-section-bullet.svg";

function FeaturesOverview() {
  const descriptionStyle =
    "text-lightGray text-[16px] leading-[25.6px] !font-sans";

  const features: { title: string; description: string }[] = useMemo(() => {
    return [
      {
        title: "Project & Builder Data",
        description:
          "Instantly access the latest project opportunities and builder information, sourced from real-time permit data.",
      },
      {
        title: "CRM",
        description:
          "Keep track of every lead, project, and conversation with a simple and powerful CRM.",
      },
      {
        title: "Analytics Dashboard",
        description:
          "Gain insights into your sales performance and find out what’s working and what needs improvement.",
      },
    ];
  }, []);

  return (
    <section>
      <SlideAndBounce>
        <article className="flex flex-col items-center mb-10">
          <div className="flex">
            <TypographyH2 className="text-primary !text-[24px]">
              Features Overview
            </TypographyH2>
          </div>
          <DescriptionHighlight />
          <TypographyP className="text-lightGray text-[18px] w-[40rem] mx-auto font-normal">
            We understand the unique challenges of the construction industry.
            That’s why BuildMapper comes packed with tools to help you succeed.
          </TypographyP>
        </article>
      </SlideAndBounce>
      <article className="grid grid-cols-1 md:grid-cols-2">
        <article className="flex flex-col gap-4">
          <div className="flex">
            <TypographyP className="text-start text-[40px] font-semibold">
              Sales
            </TypographyP>
            <LottieAnimation></LottieAnimation>
          </div>
          {features.map((feature, index) => (
            <div key={index} className="flex items-start gap-5">
              <img src={bulletIcon} alt="bullet-icon" />
              <div className="flex flex-col gap-2">
                <TypographyH3 className="text-start text-[24px] font-medium">
                  {feature.title}
                </TypographyH3>
                <TypographyP className={`${descriptionStyle} text-start`}>
                  {feature.description}
                </TypographyP>
              </div>
            </div>
          ))}
        </article>
        <div className="flex justify-end">
          <SlideIn>
            <figure>
              <img
                src={featureImage}
                alt="feature-image"
                aria-label="feature-image"
                className="w-[610px] h-[480px] object-contain"
              />
            </figure>
          </SlideIn>
        </div>
      </article>
    </section>
  );
}

function DescriptionHighlight() {
  return (
    <HeroHighlight containerClassName="size-fit bg-transparent">
      <motion.h3
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={{
          opacity: 1,
          y: [20, -5, 0],
        }}
        transition={{
          duration: 0.3,
          ease: [0.4, 0.0, 0.2, 1],
        }}
        className="text-2xl md:text-3xl lg:text-4xl text-center font-medium text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-relaxed"
      >
        The Ultimate Toolkit to
        <Highlight className="text-black dark:text-white">
          Grow Your Business
        </Highlight>{" "}
      </motion.h3>
    </HeroHighlight>
  );
}

export default React.memo(FeaturesOverview);
