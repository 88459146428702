import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useState } from "react";
import GradientButton from "@/components/ui/gradient-button";

export default function ContactSection() {
  const [labelStyle] = useState(
    "text-sm text-start font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
  );

  return (
    <div className="container mx-auto px-4 py-12 max-w-6xl text-start">
      <div className="grid lg:grid-cols-2 gap-12">
        <div className="text-start flex flex-col gap-3">
          <h4 className="text-[30px] font-bold">Get in Touch</h4>
          {/* <TypewriterEffect text="Get In Touch"  className="text-start" duration={4} /> */}
          <p className="text-[16px] font-medium text-lightGray">
            Have a question not answered on the website? We'd love to hear from
            you and help however we can!
          </p>

          <div className="bg-card border-charcoal rounded-lg shadow-lg p-6">
            <article className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="name" className={labelStyle}>
                  Name
                </label>
                <Input id="name" placeholder="Enter Name" className="h-11" />
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className={labelStyle}>
                  Email
                </label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter Email"
                  className="h-11"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="phone" className={labelStyle}>
                  Phone
                </label>
                <Input
                  id="phone"
                  type="tel"
                  placeholder="Enter Phone Number"
                  className="h-11"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="company" className={labelStyle}>
                  Company
                </label>
                <Input
                  id="company"
                  placeholder="Enter Company Name"
                  className="h-11"
                />
              </div>

              <GradientButton className="w-full h-11">Submit</GradientButton>
            </article>
          </div>
        </div>

        <div className="space-y-8">
          <Button
            variant="outline"
            className="w-full h-11 border-green-500 text-green-500 hover:bg-green-50"
          >
            Book A Demo
          </Button>

          <Accordion type="single" collapsible className="space-y-4">
            <AccordionItem value="item-1" className="border rounded-lg px-4">
              <AccordionTrigger className="text-base font-normal">
                Can I cancel my subscription at any time?
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground">
                Yes, you can cancel your subscription at any time by connecting
                to our support team.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-2" className="border rounded-lg px-4">
              <AccordionTrigger className="text-base font-normal">
                What kind of customer support do you provide?
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground">
                We offer 24/7 customer support through email, live chat, and
                phone. Our dedicated team is always ready to help you with any
                questions or concerns.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3" className="border rounded-lg px-4">
              <AccordionTrigger className="text-base font-normal">
                How do I update my account information?
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground">
                You can update your account information by logging into your
                dashboard and navigating to the account settings page.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-4" className="border rounded-lg px-4">
              <AccordionTrigger className="text-base font-normal">
                What is included in the Free and Paid Membership?
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground">
                Our Free membership includes basic features, while the Paid
                membership offers advanced features, priority support, and
                additional resources.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-5" className="border rounded-lg px-4">
              <AccordionTrigger className="text-base font-normal">
                How do I sign up for BuildMapper?
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground">
                You can sign up for BuildMapper by clicking the "Get Started"
                button on our homepage and following the registration process.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
