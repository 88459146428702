"use client";

import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

export default function TypeWriterEffect({
  text,
  duration = 1,
  className,
}: {
  text: string;
  duration?: number;
  className?: string;
}) {
  const [displayedText, setDisplayedText] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const typeText = async () => {
      for (let i = 0; i <= text.length; i++) {
        setDisplayedText(text.slice(0, i));
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      await controls.start({
        opacity: [1, 0, 1],
        transition: { duration: duration },
      });
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Pause for 2 seconds
      setDisplayedText("");
    };

    const runAnimation = async () => {
      while (true) {
        await typeText();
      }
    };

    runAnimation();

    return () => {
      controls.stop();
    };
  }, [text, controls, duration]);

  const colorizeText = (text: string) => {
    return text.split(" ").map((word, index) => {
      if (word.toLowerCase() === "discover" || word.toLowerCase() === "win") {
        return (
          <span key={index} className="text-primary">
            {word}{" "}
          </span>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  return (
    <>
      <motion.h3
        className={`text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-center leading-tight ${className}`}
        animate={controls}
        transition={{ repeat: Infinity }}
      >
        {colorizeText(displayedText)}
        <motion.span
          animate={{ opacity: [0, 1] }}
          transition={{ repeat: Infinity, duration: 0.8, ease: "easeInOut" }}
        >
          |
        </motion.span>
      </motion.h3>
    </>
  );
}
