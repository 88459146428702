// toastService.ts
import { toast, ToastOptions, Id } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface ToastService {
  success: (message: string, options?: ToastOptions) => void;
  error: (message: string, options?: ToastOptions) => void;
  info: (message: string, options?: ToastOptions) => void;
  warning: (message: string, options?: ToastOptions) => void;
  update: (id: Id, message: string, options?: ToastOptions) => void;
  dismiss: (id: Id) => void;
  dismissAll: () => void;
  loading: (message: string, options?: ToastOptions) => Id;
  resolve: (
    id: Id,
    message: string,
    isSuccess: boolean,
    options?: ToastOptions
  ) => void;
}

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000, // Close after 5 seconds
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const toastService: ToastService = {
  success(message: string, options: ToastOptions = {}) {
    toast.success(message, { ...defaultOptions, ...options });
  },

  error(message: string, options: ToastOptions = {}) {
    toast.error(message, { ...defaultOptions, ...options });
  },

  info(message: string, options: ToastOptions = {}) {
    toast.info(message, { ...defaultOptions, ...options });
  },

  warning(message: string, options: ToastOptions = {}) {
    toast.warning(message, { ...defaultOptions, ...options });
  },

  // Function to update existing toast
  update(id: Id, message: string, options: ToastOptions = {}) {
    toast.update(id, {
      render: message,
      ...defaultOptions,
      ...options,
    });
  },

  // Function to dismiss a specific toast
  dismiss(id: Id) {
    toast.dismiss(id);
  },

  // Function to dismiss all toasts
  dismissAll() {
    toast.dismiss();
  },

  // Function to show a loading toast
  loading(message: string, options: ToastOptions = {}) {
    const id = toast.loading(message, { ...defaultOptions, ...options });
    return id;
  },

  // Function to update the loading toast to success or error
  resolve(
    id: Id,
    message: string,
    isSuccess: boolean,
    options: ToastOptions = {}
  ) {
    if (isSuccess) {
      toast.update(id, {
        render: message,
        type: "success",
        isLoading: false,
        autoClose: 3000, // Closes after success
        ...defaultOptions,
        ...options,
      });
    } else {
      toast.update(id, {
        render: message,
        type: "error",
        isLoading: false,
        autoClose: 3000, // Closes after error
        ...defaultOptions,
        ...options,
      });
    }
  },
};

export default toastService;
