import { cn } from "@/lib/utils";

export function TypographyP({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p className={cn("font-sans text-[24px] font-medium text-black", className)}>
      {children}
    </p>
  );
}
