"use client";
import Lottie from "lottie-react";
import arrowAnimation from "../../assets/animations/arrow-animation.json";
const LottieAnimation = () => {
  const options = {
    loop: true, // Set to false if you want the animation to play once
    autoplay: true, // Start the animation automatically
    animationData: arrowAnimation,
  };

  return <Lottie {...options} />;
};

export default LottieAnimation;
