import { lazy } from "react";

export const KanbanBoard = lazy(() => import("@/features/kanban/KanbanBoard"));

export const LeadDetails = lazy(() => import("@/pages/Leads/LeadDetails"));

export const Listing = lazy(() => import("@/features/project-listing/Layout"));

export const BlogsListing = lazy(
  () => import("@/features/blogs/list-blogs-layout")
);

export const BlogDetails = lazy(() => import("@/features/blogs/blog-page"));

export const ProjectDetails = lazy(
  () => import("@/features/project-listing/project-details/project-details")
);
