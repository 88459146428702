import SlideAndBounce from "@/components/animations/slide-and-bounce";
import PricingPlans from "./plan-card";
import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import { Switch } from "@/components/ui/switch";
import Lottie from "lottie-react";
import arrowUpAnimation from "@/assets/animations/arrow-up-animation.json";
import { useState } from "react";
const options = {
  loop: true, // Set to false if you want the animation to play once
  autoplay: true, // Start the animation automatically
  animationData: arrowUpAnimation,
};

function PriceAndPlans() {
  const [yearly, setYearly] = useState(true);

  return (
    <section>
      <SlideAndBounce>
        <article className="flex flex-col items-center mb-20">
          <div className="flex">
            <TypographyH2 className="font-semibold !text-[40px] leading-[60px]">
              Pricing & Plans
            </TypographyH2>
          </div>
          <div className="flex items-center gap-3">
            <TypographyP className="text-[20px]">Monthly</TypographyP>
            <Switch
              checked={yearly}
              onCheckedChange={(e) => {
                console.log("on checked change", e, "yearly", yearly);
                setYearly(e);
              }}
              className=""
              id="airplane-mode"
            />
            <div>
              <TypographyP className="text-[20px]">Yearly</TypographyP>
              <div className="absolute">
                <div className="-rotate-90 size-[50px]">
                  <Lottie {...options} />
                </div>
                <div className="px-2 absolute left-[-60px] rounded-full bg-white text-primary text-[14px] font-medium">
                  Save 20%
                </div>
              </div>
            </div>
          </div>
        </article>
      </SlideAndBounce>
      <PricingPlans yearly={yearly} />
    </section>
  );
}

export default PriceAndPlans;
