// // SignUpDialog.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import Questionnaire from "@/features/questionnaire/Questionnaire";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { Progress } from "../ui/progress";
import backgroundImage from "@/assets/questionnaire/frame.webp";

// SignUpDialog component: Wraps the SignUp form inside a dialog
const QuestionnaireDialog: React.FC = () => {
  //   const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const currentStep = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.currentStep
  );
  const questions = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.questions
  );

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalQuestions = questions.length - 1;
    setProgress(((currentStep * 10) / totalQuestions) * 10);
  }, [currentStep, questions]);

  const user = useSelector(
    (state: RootState) => state.rootReducer.userReducer.user
  );

  useEffect(() => {
    console.log("new logged in user questionnaire dialog");
    if (user) setOpen(true);
  }, [user]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* Trigger for opening the dialog */}
      {/* <DialogTrigger asChild>
        <div className="flex justify-center">
          <Button type="submit" className="w-full">
            Questions
          </Button>
        </div>
      </DialogTrigger> */}

      {/* Dialog Content */}
      <DialogContent
        onPointerDownOutside={(e) => e.preventDefault()}
        aria-description="Sign up to BMP"
        aria-describedby="sign-up-dialog-description"
        className="max-w-fit h-[70vh]"
      >
        <DialogHeader>
          {progress > 0 && (
            <Progress
              className="w-full absolute top-0 left-0 h-[10px]"
              value={progress}
            />
          )}
          <DialogTitle hidden>Questionnaire dialog</DialogTitle>
          <DialogDescription hidden>Questionnaire dialog</DialogDescription>
        </DialogHeader>

        {/* Questionnaire form inside the dialog */}
        {/* <article className="w-[70vw] h-[70vh] "> */}
        <Questionnaire setOpen={setOpen} />
        {/* </article> */}
        <img
          src={backgroundImage}
          alt="background-image"
          className="absolute bottom-0 -z-10"
        />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QuestionnaireDialog);
