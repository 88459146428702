import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IQuestionnaire } from "./utils/types";
// import toastService from "@/services/toastService";
// [
//   {
//     title: "Welcome! Let’s get you rolling",
//     question: "What’s your name?*",
//     placeholder: "Type your answer here",
//     sequence: 1,
//     nextStep: 2,
//   },
//   {
//     title: "Welcome! Let’s get you rolling",
//     question: "And your phone number?*",
//     placeholder: "Type your answer here",
//     sequence: 2,
//     nextStep: 3,
//   },
//   {
//     title: "Welcome! Let’s get you rolling",
//     question: "What is the name of your company?*",
//     placeholder: "Type your answer here",
//     sequence: 3,
//     nextStep: 4,
//   },
//   {
//     title: "Welcome! Let’s get you rolling",
//     question: "What kind of work do you do?*",
//     placeholder: "Start typing to find your work",
//     sequence: 4,
//     nextStep: 5,
//   },
//   {
//     title: "Almost there",
//     question: "What city are you located in?*",
//     placeholder: "Start typing to find your city",
//     sequence: 5,
//     nextStep: 6,
//   },
//   {
//     title: "Almost there",
//     question: "What kind of projects are you looking for?*",
//     placeholder: "Choose as many as you like",
//     sequence: 6,
//     nextStep: 6,
//   },
// ];
const questions: IQuestionnaire[] = [];

const initialState = {
  questions: questions,
  currentStep: 0,
  options: [],
};

export const getQuestions = createAsyncThunk(
  "questions/getQuestions",
  async () => {
    const response = await axiosInstance({
      method: "GET",
      url: "/user/api/v1/question/getQuestions",
    });

    console.log("getQuestions response", response.data);
    const questions = response.data.data.questions.map(
      (q: { backEndKey: string; key?: string }) => {
        q.backEndKey = q.key as string;
        delete q.key;
        return q;
      }
    );
    return questions;
  }
);

export const getOptions = createAsyncThunk(
  "questions/getOptions",
  async (key: string) => {
    console.log("get options key", key);
    const response = await axiosInstance({
      method: "GET",
      url: "/user/api/v1/question/getOptions",
      params: {
        key: key,
      },
    });

    console.log("getOptions response", response.data);
    if (key == "city") {
      const options = response.data.data.options.map(
        (o: { city: string; state: string }) => {
          return `${o.city}, ${o.state}`;
        }
      );
      return options;
    }

    return response.data.data.options;
  }
);

const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setAnswerState: (state, action) => {
      const ques = state.questions.find((q) => q._id == action.payload.id);
      if (ques) ques.answer = action.payload.answer;
      console.log("answer added to the question", ques);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      });
  },
});

export const { setQuestions, setCurrentStep, setAnswerState, reset } =
  questionSlice.actions;
export default questionSlice.reducer;
