import { Button } from "@/components/ui/button"; // Adjust the path to where your Button component is located
import mobileAppImg from "@/assets/home/mobile-app.webp";
import { FlipWords } from "@/components/ui/flip-words";
import playStoreLogo from "@/assets/home/google-play-store.svg";
import appleLogo from "@/assets/home/apple-logo.svg";

const MobileAppSection = () => {
  const projectRelatedNumbers = [
    "200+ Cities Covered",
    "100+ Subcontractors",
    "200+ Suppliers",
    "1,000+ Contractors",
    "500+ Active Listings",
    "150+ Building Types",
    "300+ Service Providers",
    "250+ Equipment Options",
    "75+ Regions Served",
    "120+ Verified Vendors",
    "800+ Client Partnerships",
    "400+ Completed Projects",
    "100+ Ongoing Projects",
    "200+ Construction Experts",
    "50+ Industry Categories",
  ];

  return (
    <section className="flex flex-col md:flex-row items-center justify-between">
      {/* Left Side - Text and Buttons */}
      <div className="w-full md:w-1/2 space-y-4 text-center md:text-left">
        <p className="text-xl font-medium">Subcontractors & Suppliers</p>
        <h2 className="text-3xl font-bold text-gray-900">
          Find Your Next Project
        </h2>
        <p className="text-md font-medium text-gray-700">
          Explore current construction projects and win your next job.
        </p>
        <FlipWords
          words={["50,000+ Projects Listed", ...projectRelatedNumbers]}
          className="text-lg font-semibold text-charcoal p-0"
        />
        {/* <p className="text-lg font-semibold text-gray-800"></p> */}

        {/* Download Buttons */}
        <div className="flex justify-center md:justify-start gap-4 mt-4">
          <Button
            variant="secondary"
            className="flex items-center justify-start text-start gap-2 bg-black text-white hover:bg-gray-800"
          >
            <img
              src={playStoreLogo}
              className="size-6 object-contain"
              alt="google-play-store-logo"
            />
            <div className="flex flex-col gap-0 text-[16px]">
              <span className="!text-[11px] p-0 font-normal">Get it on</span>
              Google Play
            </div>
          </Button>
          <Button
            variant="secondary"
            className="flex items-center justify-start text-start gap-2 bg-black text-white hover:bg-gray-800"
          >
            <img
              src={appleLogo}
              className="size-6 object-contain"
              alt="google-play-store-logo"
            />
            <div className="flex flex-col gap-0 text-[16px]">
              <span className="!text-[11px] p-0 font-normal">
                Download on the
              </span>
              App Store
            </div>
          </Button>
        </div>
      </div>

      {/* Right Side - Mobile App Mockup */}
      <div className="relative w-full md:w-1/2 flex items-end">
        {/* Background Phone Image */}
        <img
          src={mobileAppImg} // Replace with actual path
          alt="App Background"
          className="h-[400px]"
        />
      </div>
    </section>
  );
};

export default MobileAppSection;
