// src/api/axios.js or axios.ts
import axios from "axios";
import { setupRequestInterceptors } from "./interceptor/request";
import { setupResponseInterceptors } from "./interceptor/response";

const DEFAULT_TIMEOUT = 10000; // Default value in case TIMEOUT_DURATION is undefined

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL, // Replace with your API base URL
  timeout: DEFAULT_TIMEOUT, // Timeout after timeout duration
  headers: {
    "Content-Type": "application/json",
  },
});

setupRequestInterceptors(axiosInstance);
setupResponseInterceptors(axiosInstance);

export default axiosInstance;
