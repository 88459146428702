import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "../App";
import Home from "@/pages/home/home";

import { Suspense } from "react";
import {
  BlogDetails,
  BlogsListing,
  KanbanBoard,
  LeadDetails,
  Listing,
  ProjectDetails,
} from "./lazyImports";
// import ProtectedRoute from "./protected-route";

{
  /* fallback={
    <div className="loading-overlay">
      <div className="spinner z-50">
        <GridLoader color="#28af4a" />
      </div>
    </div>
  } */
}

// const isAuth = Boolean(localStorage.getItem("accessToken"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route
        path="sales"
        element={
          <Suspense>
            {/* <ProtectedRoute
              isAuthenticated={Boolean(localStorage.getItem("accessToken"))}
            > */}
            <KanbanBoard />
            {/* </ProtectedRoute> */}
          </Suspense>
        }
      />
      <Route
        path="leads/:id"
        element={
          <Suspense>
            <LeadDetails />
          </Suspense>
        }
      />
      <Route
        path="explore"
        element={
          <Suspense>
            {/* <ProtectedRoute
              isAuthenticated={Boolean(localStorage.getItem("accessToken"))}
            > */}
            <Listing />
            {/* </ProtectedRoute> */}
          </Suspense>
        }
      />
      <Route
        path="blogs"
        element={
          <Suspense>
            <BlogsListing />
          </Suspense>
        }
      />
      <Route
        path="blogs/:id"
        element={
          <Suspense>
            <BlogDetails />
          </Suspense>
        }
      />
      <Route
        path="explore/:id"
        element={
          <Suspense>
            <ProjectDetails />
          </Suspense>
        }
      />
    </Route>
  )
);

export default router;
