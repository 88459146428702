import { cn } from "@/lib/utils";
import React from "react";

function TypographyH1({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h1
      className={cn(
        "scroll-m-20 font-sans text-[44px] font-extrabold tracking-normal lg:text-[54px]",
        className,
      )}
    >
      {children}
    </h1>
  );
}

export default React.memo(TypographyH1);
