export function TypographyH3({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h3
      className={`scroll-m-20 text-black text-[21px] font-bold tracking-tight leading-[32px] ${className}`}
    >
      {children}
    </h3>
  );
}
