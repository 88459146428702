import { Axios } from "axios";

export const CRYPT_CONSTANT = {
  ENCRYPTION_ALGORITHM: "aes-256-cbc",
  ENCRYPTION_KEY: {
    type: "Buffer",
    data: [
      11, 168, 197, 219, 125, 165, 21, 188, 126, 47, 44, 71, 208, 94, 18, 234,
      139, 51, 84, 5, 107, 7, 196, 225, 132, 140, 179, 36, 231, 69, 221, 149,
    ],
  },
};

export const setupRequestInterceptors = (axiosInstance: Axios) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Modify request config here (e.g., adding authorization tokens)
      const auth = "YnVpbGRNYXBwZXIxMjM0NS1jbGllbnQ6cGFzczEyMzQ1LWNsaWVudA==";
      config.headers["Authorization"] = `Basic ${auth}`;
      config.headers["test"] = `true`;
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers["access-token"] = `bearer ${token}`;
      }

      console.log("config body", config.data);

      // const body = await encrypt(config.data);
      // console.log("enctrypted body", body);
      // config.data = body;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// async function encrypt(data: Record<string, unknown>): Promise<string> {
//   const text = JSON.stringify(data);
//   const iv = window.crypto.getRandomValues(new Uint8Array(16)); // Initialization vector

//   // Convert text to ArrayBuffer
//   const encoder = new TextEncoder();
//   const encodedText = encoder.encode(text);

//   const encryptionKeyBytes = CRYPT_CONSTANT.ENCRYPTION_KEY.data;

//   // Convert byte array to ArrayBuffer
//   const keyBuffer = new Uint8Array(encryptionKeyBytes);

//   // Import the key for AES-CBC encryption
//   const key = await window.crypto.subtle.importKey(
//     "raw", // The key is provided in raw format
//     keyBuffer, // The key as ArrayBuffer
//     { name: CRYPT_CONSTANT.ENCRYPTION_ALGORITHM }, // AES-CBC algorithm
//     false, // Non-extractable key
//     ["encrypt", "decrypt"] // Usages
//   );

//   // Encrypt the data
//   const encrypted = await window.crypto.subtle.encrypt(
//     {
//       name: CRYPT_CONSTANT.ENCRYPTION_ALGORITHM,
//       iv: iv,
//     },
//     key,
//     encodedText
//   );

//   // Convert ArrayBuffer to hex for the encrypted data and IV
//   const encryptedHex = bufferToHex(new Uint8Array(encrypted));
//   const ivHex = bufferToHex(iv);

//   return `${ivHex}:${encryptedHex}`;
// }

// Helper function to convert ArrayBuffer to hex string
// function bufferToHex(buffer: Uint8Array): string {
//   return Array.from(buffer)
//     .map((b) => b.toString(16).padStart(2, "0"))
//     .join("");
// }
