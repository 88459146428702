import SlideAndBounce from "@/components/animations/slide-and-bounce";
import TypewriterEffect from "@/components/animations/typewriter-effect";
import { TypographyP } from "@/components/typography/TypographyP";
import AmesImg from "@/assets/partners/ames-tile-stones.webp";
import fibreTechImg from "@/assets/partners/fibretech.webp";
import interGlobeImg from "@/assets/partners/interglobe.webp";
import rbsImg from "@/assets/partners/rbs.webp";
import griffImg from "@/assets/partners/griff.webp";
import fireplacesImg from "@/assets/partners/fire-places.webp";
import worksafeImg from "@/assets/partners/work-safe-bc.webp";
import pocoImg from "@/assets/partners/poco.webp";
import coastApplImg from "@/assets/partners/coast-appliances.webp";
import crownImg from "@/assets/partners/crown.webp";
import uppalImg from "@/assets/partners/uppal.webp";
import andrewSheretImg from "@/assets/partners/andrew-sheret-ltd.webp";
import ridgeMontImg from "@/assets/partners/ridge-mont.webp";
import capitalStoneImg from "@/assets/partners/capital-tile-stone.webp";
import colonialImg from "@/assets/partners/colonial.webp";
import pattersonWhittckerImg from "@/assets/partners/patterson-whittacker.webp";
import AoneImg from "@/assets/partners/Aone.webp";
import htaImg from "@/assets/partners/hta.webp";
import klassenImg from "@/assets/partners/klassen.webp";
import goldStarImg from "@/assets/partners/gold-star.webp";
import westWindowImg from "@/assets/partners/west-coast-windows.webp";
import northCoastImg from "@/assets/partners/northcoast.webp";
import urbanDryImg from "@/assets/partners/urban-drywall.webp";
import islandGlassImg from "@/assets/partners/island-glass-mirror.webp";
import crystalKitchensImg from "@/assets/partners/crystal-kitchens.webp";
import galaxyFirePlacesImg from "@/assets/partners/galaxy-fireplaces.webp";
import wintegraImg from "@/assets/partners/wintegra.webp";
import aztecImg from "@/assets/partners/aztec.webp";

const partners = [
  {
    image: AmesImg,
  },
  {
    image: fibreTechImg,
  },
  {
    image: pocoImg,
  },
  {
    image: rbsImg,
  },
  {
    image: griffImg,
  },
  {
    image: fireplacesImg,
  },
  {
    image: worksafeImg,
  },
  {
    image: coastApplImg,
  },
  {
    image: crownImg,
  },
  {
    image: interGlobeImg,
  },
  {
    image: uppalImg,
  },
  {
    image: andrewSheretImg,
  },
  {
    image: ridgeMontImg,
  },
  {
    image: capitalStoneImg,
  },
  {
    image: colonialImg,
  },
  {
    image: pattersonWhittckerImg,
  },
  {
    image: AoneImg,
  },
  {
    image: htaImg,
  },
  {
    image: klassenImg,
  },
  {
    image: goldStarImg,
  },
  {
    image: westWindowImg,
  },
  {
    image: northCoastImg,
  },
  {
    image: urbanDryImg,
  },
  {
    image: islandGlassImg,
  },
  {
    image: crystalKitchensImg,
  },
  {
    image: galaxyFirePlacesImg,
  },
  {
    image: wintegraImg,
  },
  {
    image: aztecImg,
  },
];

function TrustedPartners() {
  return (
    <>
      <SlideAndBounce>
        <article className="flex flex-col items-center mb-10">
          <div className="flex">
            <TypewriterEffect text="Trusted Partners" />
          </div>
          <TypographyP className="text-lightGray text-[18px] w-[40rem] mx-auto font-normal">
            Some of our picked properties near you location.
          </TypographyP>
        </article>
      </SlideAndBounce>

      <article className="grid grid-cols-2 md:grid-cols-5 gap-4 lg:grid-cols-5 place-items-center justify-items-center">
        {partners.map((partner, i) => (
          <div
            className="transform hover:scale-105 duration-300 ease-in-out"
            key={i}
          >
            <img
              className="w-[190px] h-[81px] object-contain"
              src={partner.image}
              alt="partner-image"
            />
          </div>
        ))}
      </article>
    </>
  );
}

export default TrustedPartners;
