import React from "react";

import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";
import logo from "@/assets/logo.svg";
import { useTranslation } from "react-i18next";
import { LOCALIZATION_KEYS } from "@/i18n/keys";

type FOOTER_COLUMN_ITEM_TYPE = {
  title: string;
  href: string;
};

type FOOTER_COLUMN_TYPE = {
  title: string;
  items: FOOTER_COLUMN_ITEM_TYPE[];
};
import footerImg from "../../assets/footer-bg.webp";
import { IconType } from "react-icons/lib";

// Footer component for the BuildMapper application
const Footer: React.FC = () => {
  const { t } = useTranslation();

  const footerColumns: FOOTER_COLUMN_TYPE[] = [
    {
      title: t(LOCALIZATION_KEYS.FOOTER.FEATURES),
      items: [
        {
          title: t(LOCALIZATION_KEYS.FOOTER.FIND_SUBCONTRACTORS),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.FIND_PROJECTS),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.ROI_CALCULATOR),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.LEARN),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.FEATURE_GOES_HERE),
          href: "#",
        },
      ],
    },
    {
      title: t(LOCALIZATION_KEYS.FOOTER.PROPERTY_TYPES),
      items: [
        {
          title: t(LOCALIZATION_KEYS.FOOTER.RESIDENTIAL_PROPERTY),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.COMMERCIAL_PROPERTY),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.SOCIETY_PROPERTY),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.OFFICE_PROPERTY),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.STORE_PROPERTY),
          href: "#",
        },
      ],
    },
    {
      title: t(LOCALIZATION_KEYS.FOOTER.RESOURCES),
      items: [
        {
          title: t(LOCALIZATION_KEYS.FOOTER.EXPLORE),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.HOW_IT_WORKS),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.PRICING),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.BLOG),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.FAQ),
          href: "#",
        },
      ],
    },
    {
      title: t(LOCALIZATION_KEYS.FOOTER.QUICK_LINKS),
      items: [
        {
          title: t(LOCALIZATION_KEYS.FOOTER.SIGN_UP),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.LOGIN),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.TERMS_AND_CONDITION),
          href: "#",
        },
        {
          title: t(LOCALIZATION_KEYS.FOOTER.PRIVACY_POLICY),
          href: "#",
        },
      ],
    },
  ];

  return (
    <footer className="pt-8 w-[100vw] border-t border-gray-200">
      <div className="w-full px-[8%] mx-auto">
        {/* Logo Section */}
        <div className="flex justify-start">
          <img
            src={logo} // Add your logo path here
            alt="BuildMapper"
            className="h-12 mb-4"
          />
        </div>

        {/* Main footer content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-8">
          {footerColumns.map((column, index) => (
            <FooterColumn
              key={index}
              title={column.title}
              items={column.items}
            />
          ))}
        </div>

        {/* Footer Bottom Section */}
      </div>
      {/* className="absolute inset-0 w-full h-full repeat-0 bg-contain" */}
      <div
        style={{
          backgroundImage: `url(${footerImg})`,
        }}
        className="inset-0 w-full h-64 bg-no-repeat bg-origin-border bg-bottom bg-contain  mt-8 border-t pt-6 px-[8%]  text-gray-500 text-sm text-center flex justify-between items-start"
      >
        {/* Copyright Notice */}
        <p className="text-[16px] font-medium">
          © {new Date().getFullYear()} {t(LOCALIZATION_KEYS.APP_AUTHOR)}.{" "}
          {t(LOCALIZATION_KEYS.APP_AUTHOR_RIGHTS)}
        </p>

        {/* Social Media Icons */}
        <div className="flex items-end gap-10">
          <div className="pb-[3px]">
            <SocialIcon href="#" Icon={FaFacebookF} />
          </div>
          <SocialIcon href="#" Icon={FaInstagram} />
          <SocialIcon href="#" Icon={FaYoutube} />
        </div>
      </div>
    </footer>
  );
};

// Helper component for footer columns
const FooterColumn: React.FC<FOOTER_COLUMN_TYPE> = ({ title, items }) => (
  <div className="col-span-1">
    <h3 className="font-bold text-black mb-4">{title}</h3>
    <ul className="space-y-2">
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.href} className="text-lightGray font-normal hover:text-gray-700">
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

// Helper component for social media icons
const SocialIcon: React.FC<{ href: string; Icon: IconType }> = ({
  href,
  Icon,
}) => (
  <a href={href} className="text-primary hover:text-bmpGreen-800">
    <Icon size={25} />
  </a>
);

export default Footer;
