"use client";
import React, { useRef } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import * as ProgressPrimitive from "@radix-ui/react-progress";

export const StickyScroll = ({
  content,
  contentClassName,
}: {
  content: {
    title: string;
    description: string;
    content?: React.ReactNode;
  }[];
  contentClassName?: string;
}) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    // uncomment line 22 and comment line 23 if you DONT want the overflow container and want to have it change on the entire page scroll
    // target: ref,
    container: ref,
    offset: ["start start", "end start"],
  });
  const cardLength = content.length;

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const cardsBreakpoints = content.map(
      (_, index) => index / (cardLength + 4)
    );
    const closestBreakpointIndex = cardsBreakpoints.reduce(
      (acc, breakpoint, index) => {
        const distance = Math.abs(latest - breakpoint);
        if (distance < Math.abs(latest - cardsBreakpoints[acc])) {
          return index;
        }
        return acc;
      },
      0
    );
    setActiveCard(closestBreakpointIndex);
  });

  const progressPercentage = ((activeCard + 1) / content.length) * 100;

  console.log("progress percentage", progressPercentage);

  return (
    <section>
      <style>
        {`
          .scrollbar-hide::-webkit-scrollbar {
            display: none; /* Hides scrollbar */
          }

          .scrollbar-hide {
            scrollbar-width: none; /* Hides scrollbar */
          }
        `}
      </style>
      <div className="flex flex-col items-center justify-start gap-5 absolute left-0 me-3">
        <VerticalProgress
          value={progressPercentage}
          max={content.length}
          className="w-[4px] h-[200px] bg-bmpGreen-100"
        />
        <p className="-rotate-90 text-[15px] font-bold">
          0{activeCard + 1} /{" "}
          <span className="text-lightGray">0{content.length}</span>
        </p>
      </div>

      <motion.div
        className="h-[22rem] py-5 overflow-y-auto scrollbar-hide flex justify-center items-start relative space-x-10 rounded-md"
        ref={ref}
      >
        <div className="div relative flex items-start px-4">
          <div className="max-w-2xl">
            {content.map((item, index) => (
              <div key={item.title + index} className="mb-5">
                <motion.h2
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 0.3,
                  }}
                  className="text-2xl font-bold text-charcoal text-start"
                >
                  {item.title}
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 0.3,
                  }}
                  className="text-lg font-medium text-slate-600 max-w-sm mt-4 text-start"
                >
                  {item.description}
                </motion.p>
              </div>
            ))}
            {/* <div className="h-40" /> */}
          </div>
        </div>
        <div
          className={cn(
            "hidden lg:block h-fit w-[40%] rounded-md sticky top-0 overflow-hidden",
            contentClassName
          )}
        >
          {content[activeCard].content ?? null}
        </div>
      </motion.div>
    </section>
  );
};

const VerticalProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      "relative h-48 w-4 overflow-hidden rounded-full bg-secondary",
      className
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="w-full bg-primary transition-all duration-500 ease-in-out"
      style={{ height: `${value || 0}%` }}
    />
  </ProgressPrimitive.Root>
));
VerticalProgress.displayName = ProgressPrimitive.Root.displayName;

interface VerticalTextProps {
  text: string;
  className?: string;
}

export default function VerticalText({
  text,
  className = "",
}: VerticalTextProps) {
  return (
    <div className={`flex flex-col items-center ${className}`}>
      {text.split("").map((char, index) => (
        <span
          key={index}
          className="transform rotate-90 text-teal-200 text-xs font-light tracking-wider my-[-0.25em]"
          style={{ fontFamily: "monospace" }}
        >
          {char}
        </span>
      ))}
    </div>
  );
}
