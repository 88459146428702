// import axiosInstance from "@/api/axios";
import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Lead, Stage } from "./services/KanbanService";

type KANBAN = {
  stages: Stage[];
  leads: Lead[];
};

const initialState: KANBAN = {
  stages: [],
  leads: [],
};

export const getStages = createAsyncThunk("kanban/getStages", async () => {
  const response = await axiosInstance({
    method: "GET",
    url: "/api/v1/stages",
  });
  console.log("response", response);
  // setColumns(response.data.data.data.stages);
  return response.data.data.data.stages;
});

const kanbanSlice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    setStages: (state, action) => {
      state.stages = action.payload;
    },
    setLeads: (state, action) => {
      state.leads = action.payload;
    },
  },
});

export const { setStages, setLeads } = kanbanSlice.actions;
export default kanbanSlice.reducer;
