import { TypographyH3 } from "@/components/typography/TypographyH3";
import { INPUT_TYPE, IQuestionnaire } from "../utils/types";
import { TypographyP } from "@/components/typography/TypographyP";
import GradientButton from "@/components/ui/gradient-button";
import TextInput from "./input-types/TextInput";
import SelectInput from "./input-types/SelectInput";
import { useCallback, useEffect, useState } from "react";
import PhoneInput from "./input-types/PhoneInput";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { setAnswerState } from "../QuestionSlice";
import MultiSelectInput from "./input-types/MultiSelectInput";

interface IQuestion extends IQuestionnaire {
  setStep: (step: number, lastAnswer?: string | string[]) => void;
}

function Question(ques: IQuestion) {
  // function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
  //   if (event.key == "Enter") {
  //     event.preventDefault();
  //   }
  // }

  const { title, question, placeholder, sequence: step, setStep } = ques;
  const [answer, setAnswer] = useState<string | string[]>("");
  const dispatch = useDispatch<AppDispatch>();

  const options = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.options
  );

  const questions = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.questions
  );

  useEffect(() => {
    setAnswer("");
  }, [ques]);

  // useEffect(() => {
  //   if (
  //     ques.inputType == INPUT_TYPE.MULTI_SELECT ||
  //     ques.inputType == INPUT_TYPE.SINGLE_SELECT
  //   ) {
  //     console.log("key in", ques, ques.backEndKey);
  //     dispatch(getOptions(ques.backEndKey));
  //   }
  // }, [dispatch, ques]);

  function saveAnswer(ans: string | string[]) {
    console.log("setanswer line43", ans);
    setAnswer(ans);
  }

  // function onSubmit(ans?: string | string[]) {
  //   console.log("onSubmit answer line55", answer, ans);
  //   const answ = ans || answer;
  //   if (!answ) return;
  //   dispatch(setAnswerState({ id: ques._id, answer: answ }));
  //   console.log("new temp question with answer", ques);
  //   if (step == questions.length) {
  //     console.log("onSubmit line58", step, questions.length);
  //     setStep(step + 1, answ);
  //   } else {
  //     console.log("onSubmit line61", step, questions.length);
  //     setStep(step + 1);
  //   }
  // }

  const onSubmit = useCallback(
    (ans?: string | string[]) => {
      console.log("onSubmit answer line55", answer, ans);
      const answ = ans || answer;
      if (!answ) return;
      dispatch(setAnswerState({ id: ques._id, answer: answ }));
      console.log("new temp question with answer", ques);
      if (step == questions.length) {
        console.log("onSubmit line58", step, questions.length);
        setStep(step + 1, answ);
      } else {
        console.log("onSubmit line61", step, questions.length);
        setStep(step + 1);
      }
    },
    [answer, dispatch]
  );

  const renderInput = useCallback(() => {
    switch (ques.inputType) {
      case INPUT_TYPE.TEXT: {
        return (
          <TextInput
            placeholder={placeholder}
            onSubmit={saveAnswer}
            proceed={onSubmit}
          />
        );
      }
      case INPUT_TYPE.SINGLE_SELECT: {
        return (
          <SelectInput
            options={options}
            onSubmit={saveAnswer}
            placeholder={placeholder}
            backEndKey={ques.backEndKey}
            proceed={onSubmit}
          />
        );
      }
      case INPUT_TYPE.MULTI_SELECT: {
        return (
          <MultiSelectInput
            options={options}
            onSubmit={saveAnswer}
            placeholder={placeholder}
            backEndKey={ques.backEndKey}
            proceed={onSubmit}
          />
        );
      }
      case INPUT_TYPE.PHONE_NUMBER: {
        return (
          <PhoneInput
            onSubmit={saveAnswer}
            placeholder={placeholder}
            proceed={onSubmit}
          />
        );
      }
      // case INPUT_TYPE.NUMBER: {
      // }
      default: {
        return <TextInput placeholder={placeholder} onSubmit={saveAnswer} />;
      }
    }
  }, [ques.inputType, placeholder, options, ques.backEndKey, onSubmit]);

  return (
    <div className="flex justify-start items-center ps-[10%]">
      <div className="">
        {/* Heading */}
        {title && (
          <TypographyH3 className="text-[24px] text-charcoal font-medium mb-6">
            {title}
          </TypographyH3>
        )}

        {/* Question */}
        <div className="mb-4">
          <TypographyP className="text-[20px] text-bmpGreen-500 font-medium mb-1">
            {step}. {question}
          </TypographyP>
          {renderInput()}
        </div>

        {/* Submit Button */}
        <div className="flex items-center space-x-2">
          <GradientButton
            onClick={() => {
              onSubmit();
            }}
          >
            <span className="font-bold">Proceed</span>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="e2688g11ra"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="25"
              >
                <path fill="#D9D9D9" d="M0 .5h24v24H0z" />
              </mask>
              <g mask="url(#e2688g11ra)">
                <path
                  d="m10.6 17.1 7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4 4.25 4.25zm1.4 5.4a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12.5c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2.5c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12.5a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 0 1-3.9.788zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.625 20 14.733 20 12.5c0-2.233-.775-4.125-2.325-5.675C16.125 5.275 14.233 4.5 12 4.5c-2.233 0-4.125.775-5.675 2.325C4.775 8.375 4 10.267 4 12.5c0 2.233.775 4.125 2.325 5.675C7.875 19.725 9.767 20.5 12 20.5z"
                  fill="#fff"
                />
              </g>
            </svg>
          </GradientButton>
          <p className="text-gray-400">press Enter ⏎</p>
        </div>
      </div>
    </div>
  );
}

export default Question;
