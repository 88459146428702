import { useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js";
import { InputProps } from "./Input";
import "react-phone-number-input/style.css";

function PhoneInput(props: InputProps) {
  const [value, setValue] = useState<string | E164Number | undefined>();

  useEffect(() => {
    props.onSubmit(value as string);
  }, [value, props]);

  return (
    <div className="max-w-md">
      <ReactPhoneInput
        id="phone"
        placeholder="Enter phone number"
        value={value}
        onChange={setValue}
        defaultCountry="CA"
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
      />
      {/* onKeyDown={(e) => {
          if (e.key === "Enter") {
            // e.preventDefault(); // Prevent form submission if inside a form
            if (props.proceed) props.proceed(value);
          }
        }} */}
      <style>{`
        .PhoneInputInput {
          flex: 1;
          min-width: 0;
          height: 40px;
          padding-left: 0.5rem;
          border: none;
          outline: none;
        }
        .PhoneInputCountry {
          position: relative;
          align-self: stretch;
          display: flex;
          align-items: center;
          background-color: #f3f4f6;
          padding: 0 0.5rem;
          border-radius: 0.375rem 0 0 0.375rem;
        }
      `}</style>
    </div>
  );
}

export default PhoneInput;
