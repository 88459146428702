import axiosInstance from "@/api/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toastService from "@/services/toastService";
import { User } from "./types";
import { saveToken } from "@/firebase";

const initialState = {
  user: null as User | null,
  questionDone: false,
  country: "CA",
};

export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (credentials: { email: string; password: string }) => {
    const response = await axiosInstance({
      method: "POST",
      url: "/user/api/v1/user/signup",
      data: credentials,
    });

    toastService.success(response.data.message);
    console.log(response);
    return response.data.data;
    // return dummyUsers;
  }
);

export const signInUser = createAsyncThunk(
  "user/signUpUser",
  async (credentials: { email: string; password: string }) => {
    const response = await axiosInstance({
      method: "POST",
      url: "/user/api/v1/user/login",
      data: credentials,
    });

    console.log(response);
    return response.data.data;
  }
);

export const logoutUser = createAsyncThunk("user/signUpUser", async () => {
  const response = await axiosInstance({
    method: "POST",
    url: "/user/api/v1/user/logout",
    // data: credentials,
  });

  console.log(response);
  return response.data.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      if (action.payload && action.payload?.accessToken) {
        state.questionDone = action.payload.questionnaireFilled;
        localStorage.setItem("user", JSON.stringify(action.payload));
        localStorage.setItem("accessToken", action.payload.accessToken);
        saveToken();
      }
    },
    toggleQuestion: (state, action) => {
      state.questionDone = action.payload;
      localStorage.setItem("questionDone", action.payload);
    },
    changeCountry: (state, action) => {
      console.log("change country called", action);
      state.country = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, () => {
      localStorage.clear();
      return initialState;
    });
  },
});

export const { setUser, toggleQuestion, changeCountry } = userSlice.actions;
export default userSlice.reducer;
