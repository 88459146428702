import WelcomeComponent from "./ui/Welcome";
import Question from "./ui/Questions";
import { AppDispatch, RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions, reset, setCurrentStep } from "./QuestionSlice";
import { Button } from "@/components/ui/button";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import { toggleQuestion } from "../auth/UserSlice";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useLoading } from "@/contexts/LoadingContext";
import axiosInstance from "@/api/axios";
import toastService from "@/services/toastService";

function Questionnaire({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const questions = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.questions
  );

  const currentStep = useSelector(
    (state: RootState) => state.rootReducer.questionnaireReducer.currentStep
  );

  const { setLoading } = useLoading();

  function setStep(step: number, lastAnswer?: string | string[]) {
    console.log("set step called", step);
    if (step <= questions.length) {
      dispatch(setCurrentStep(step));
    } else {
      pushAnswers(lastAnswer);
    }
  }

  async function pushAnswers(lastAnswer?: string | string[]) {
    setLoading(true);

    console.log("last answer", lastAnswer, currentStep);
    console.log("push answer questions", questions);
    // eslint-disable-next-line no-debugger
    const answers = questions.map((q) => {
      if (q.backEndKey == "city") {
        const answer = String(q.answer).split(",");
        return {
          questionId: q._id,
          answer: { city: answer[0], state: answer[1] },
        };
      }
      return { questionId: q._id, answer: q.answer };
    });

    answers[answers.length - 1].answer = lastAnswer || [];
    const response = await axiosInstance({
      method: "PUT",
      url: "/user/api/v1/question/pushAnswer",
      data: {
        pushObject: answers,
      },
    });
    setLoading(false);
    console.log("push answers response", response);
    if (response.status == 200) {
      dispatch(toggleQuestion(true));
      toastService.success("Your answers are succesfully submitted! Thankyou.");
      dispatch(reset());
      setOpen(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getQuestions());

    setLoading(false);
  }, [dispatch, setLoading]);

  console.log("questions line60", questions);

  const renderComponent = () => {
    if (currentStep === 0) {
      return <WelcomeComponent nextStep={1} setStep={setStep} />;
    } else if (questions.length > 0) {
      return <Question {...questions[currentStep - 1]} setStep={setStep} />;
    }
  };

  return (
    <article className="h-full w-[60vw] relative bg-contain bg-no-repeat bg-bottom">
      {/* style={{
        backgroundImage: `url(${backgroundImage})`,
      }} */}
      {renderComponent()}
      {/* <div className="relative"> */}
      {currentStep > 0 && (
        <div className="absolute bottom-10 right-10">
          <Button
            onClick={() => {
              setStep(Math.max(currentStep - 1, 0));
            }}
            size="icon"
            variant="outline"
            className="text-2xl me-1"
          >
            <RxCaretLeft />
          </Button>
          <Button
            onClick={() => {
              setStep(Math.min(currentStep + 1, questions.length - 1));
            }}
            size="icon"
            variant="outline"
            className="text-2xl"
            disabled
          >
            <RxCaretRight />
          </Button>
          {/* </div> */}
        </div>
      )}
    </article>
  );
}

export default Questionnaire;
