"use strict";

import logo from "@/assets/logo.svg";
import exploreIcon from "@/assets/icons/explore.svg";
// import prosIcon from "@/assets/icons/pros.svg";
import { NavLink } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { LOCALIZATION_KEYS } from "@/i18n/keys";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import canadaFlag from "@/assets/countries/canada-logo.svg";
import americaFlag from "@/assets/countries/united-states-flag.svg";
import SignUpDialog from "@/components/dialogs/SignUpDialog";
import QuestionnaireDialog from "../dialogs/QuestionnaireDialog";
import LoginDialog from "../dialogs/LoginDialog";
import  { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import {
  setUser,
  toggleQuestion,
  changeCountry,
  logoutUser,
} from "@/features/auth/UserSlice";
import { useLoading } from "@/contexts/LoadingContext";
import { User } from "@/features/auth/types";

export function Header({ className }: { className?: string }) {
  const questionDone = useSelector(
    (state: RootState) => state.rootReducer.userReducer.questionDone
  );
  const user = useSelector(
    (state: RootState) => state.rootReducer.userReducer.user
  );
  const dispatch = useDispatch<AppDispatch>();

  const [selectedCountry, setSelectedCountry] = useState<"CA" | "US">("CA");
  const [userName, setUserName] = useState("");
  const { setLoading } = useLoading();

  useEffect(() => {
    dispatch(changeCountry(selectedCountry));
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    console.log("user", user);
    if (user) {
      const name = user.email?.split("@")[0];
      setUserName(name);
    } else {
      const localUser = JSON.parse(localStorage.getItem("user") + "");
      if (localUser) {
        console.log("local user", localUser);
        dispatch(setUser(localUser));
        const questionDone = localStorage.getItem("questionDone");
        if (questionDone) {
          dispatch(toggleQuestion(questionDone == "true"));
        }
      }
    }
  }, [user]);

  console.log("userName", userName);

  const logout = () => {
    try {
      setLoading(true);
      dispatch(logoutUser()).then(() => {
        window.location.reload();
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <header
      className={`sticky top-0 z-50 w-[100vw] bg-white flex justify-around items-center h-[60px] drop-shadow-lg ${className}`}
    >
      <figure>
        <NavLink to="/">
          <img src={logo} alt="company-logo" className="h-[40px]" />
        </NavLink>
        {/* <OptimizedImage src={logo} alt="company-logo" className="h-[40px]" /> */}
      </figure>
      <NavMenu user={user} />
      <div className="flex items-center gap-2">
        <NavLink to="/sign-up" aria-label="Login">
          <Select
            value={selectedCountry}
            onValueChange={(e) => {
              setSelectedCountry(e as typeof selectedCountry);
            }}
          >
            <SelectTrigger className="w-[80px]">
              <SelectValue placeholder="Country" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="CA">
                <img
                  src={canadaFlag}
                  alt="canada-flag"
                  width="32px"
                  height="32px"
                />
              </SelectItem>
              <SelectItem value="US">
                <img
                  src={americaFlag}
                  alt="usa-flag"
                  width="32px"
                  height="32px"
                />
              </SelectItem>
            </SelectContent>
          </Select>
        </NavLink>
        {/* <NavLink to="/sign-up" aria-label="Login">
          <Button variant="outline" className="text-charcoal ">
            {t(LOCALIZATION_KEYS.NAV_LINKS.LOGIN)}
          </Button>
        </NavLink> */}

        {user ? (
          <DropdownMenu>
            <DropdownMenuTrigger className="border-none outline-none">
              <Button
                variant="outline"
                className="flex gap-2 items-center justify-center"
              >
                <CgProfile className="text-[20px]" />
                <span>{userName ? userName : "John Doe"}</span>
                <GiHamburgerMenu className="text-[20px]" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-52">
              <DropdownMenuLabel className="text-charcoal text-[16px]">
                My Account
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="text-charcoal text-[16px]">
                Profile
              </DropdownMenuItem>
              <DropdownMenuItem className="text-charcoal text-[16px]">
                My Leads
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={logout}
                className="text-charcoal text-[16px]"
              >
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <>
            <LoginDialog />

            {/* sign up dialog */}
            <SignUpDialog />
          </>
        )}
        {!questionDone && <QuestionnaireDialog />}
      </div>
    </header>
  );
}

const NavMenu = ({ user }: { user: User | null }) => {
  const { t } = useTranslation();
  return (
    <nav className="flex items-center">
      <NavLink to={user ? "/explore" : ""} aria-label="Explore projects">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200">
          <img
            src={exploreIcon}
            alt="explore-icon"
            width="20px"
            height="20px"
            className="me-1"
          />
          {t(LOCALIZATION_KEYS.NAV_LINKS.EXPLORE)}
        </Button>
      </NavLink>
      <NavLink to={user ? "/sales" : ""} aria-label="How it works">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 ">
          Sales Hub
        </Button>
      </NavLink>
      <NavLink to="/" aria-label="Pricing">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 ">
          {t(LOCALIZATION_KEYS.NAV_LINKS.PRICING)}
        </Button>
      </NavLink>
      <NavLink to="/blogs" aria-label="Blog">
        <Button className="bg-transparent text-[16px] text-charcoal hover:bg-bmpGreen-200 ">
          Blog
        </Button>
      </NavLink>
    </nav>
  );
};
