// "use client";
// import React from "react";
import { ContainerScroll } from "@/components/ui/container-scroll-animation";
// import Image from "next/image";
import discoverImage from "@/assets/home/discover-and-win.svg";
// import { TypographyP } from "@/components/typography/TypographyP";

export function ContainerScrollDemo() {
  return (
    <div className="flex flex-col overflow-hidden">
      <ContainerScroll
        titleComponent={
          <>
            {/* <TypographyP className="text-4xl font-semibold text-black dark:text-white"> */}
            {/* Unleash the power of <br />
              <span className="text-4xl md:text-[6rem] font-bold mt-1 leading-none">
                Scroll Animations
              </span> */}
            {/* Discover the latest projects in your area at the perfect time. */}
            {/* </TypographyP> */}
          </>
        }
      >
        <img
          src={discoverImage}
          alt="hero"
          height={720}
          width={1400}
          className="mx-auto rounded-2xl object-cover h-full object-left-top"
          draggable={false}
        />
      </ContainerScroll>
    </div>
  );
}
