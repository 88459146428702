import { Card } from "@/components/ui/card";
import { TypographyP } from "@/components/typography/TypographyP";
import profileImage from "@/assets/questionnaire/profile.svg";
import builderImage from "@/assets/questionnaire/builders.svg";
import leadsImage from "@/assets/questionnaire/leads.svg";
import { TypographyH2 } from "@/components/typography/TypographyH2";
import GradientButton from "@/components/ui/gradient-button";

function WelcomeComponent({
  nextStep,
  setStep,
}: {
  nextStep: number;
  setStep: (step: number) => void;
}) {
  // useEffect(() => {
  //   console.log(nextStep, setStep);
  // }, [nextStep, setStep]);

  return (
    <section className="h-[full] flex flex-col items-center">
      <TypographyH2 className="text-[30px] text-charcoal font-semibold mb-4 text-center">
        Welcome to BuildMapper!
      </TypographyH2>
      <p className="text-[14px] text-lightGray leading-[26px] mb-6 w-[50%] mx-auto text-center">
        We're excited to have you on board! To get the most out of BuildMapper,
        we'll guide you through a few steps to set up your account and get you
        started on the path to success.
      </p>
      <article className="flex justify-center gap-4">
        <Card className="border-none shadow-sm">
          <img
            src={profileImage}
            className="size-[80px] object-contain mx-auto"
            alt="Profile"
          />
          <TypographyP className="text-charcoal text-[14px] text-center w-[70%] mx-auto mt-3">
            Complete your profile form to access custom leads
          </TypographyP>
        </Card>
        <Card className="border-none shadow-sm">
          <img
            src={leadsImage}
            className="size-[80px] object-contain mx-auto"
            alt="Leads"
          />
          <TypographyP className="text-charcoal text-[14px] text-center w-[70%] mx-auto mt-3">
            View your custom leads, tailored to your needs
          </TypographyP>
        </Card>
        <Card className="border-none shadow-sm">
          <img
            src={builderImage}
            className="size-[80px] object-contain mx-auto"
            alt="Builder"
          />
          <TypographyP className="text-charcoal text-[14px] text-center w-[70%] mx-auto mt-3">
            Connect with builders and developers and get jobs
          </TypographyP>
        </Card>
      </article>
      <article className="flex justify-center mt-10">
        <GradientButton onClick={() => setStep(nextStep)}>
          Get Started
        </GradientButton>
      </article>
    </section>
  );
}

export default WelcomeComponent;
