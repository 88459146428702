import { LOCALIZATION_KEYS } from "./keys";

const HindiTranslations = {
  translation: {
    [LOCALIZATION_KEYS.SCREENS.LOGIN_SCREEN]: "लॉगिन स्क्रीन",
    [LOCALIZATION_KEYS.SCREENS.HOME_SCREEN]: "होम स्क्रीन",
    [LOCALIZATION_KEYS.SCREENS.EXPLORE_SCREEN]: "अन्वेषण स्क्रीन",
    [LOCALIZATION_KEYS.SCREENS.CART_SCREEN]: "कार्ट स्क्रीन",
    [LOCALIZATION_KEYS.SCREENS.ACCOUNT_SCREEN]: "खाता स्क्रीन",
    [LOCALIZATION_KEYS.BUTTON_TEXTS.MOVE_TO_HOME]: "होम स्क्रीन पर जाएँ",
    [LOCALIZATION_KEYS.LOGIN_WITH_GOOGLE]: "गूगल लॉगिन करें",
    [LOCALIZATION_KEYS.LOGIN_WITH_FB]: "फ़ेसबुक लॉगिन करें",
    [LOCALIZATION_KEYS.LOGIN_WITH_APPLE]: "ऐप्पल लॉगिन करें",
    [LOCALIZATION_KEYS.APP_AUTHOR]: "बिल्डमैपर",
    [LOCALIZATION_KEYS.APP_AUTHOR_RIGHTS]: "सभी अधिकार सुरक्षित हैं",
    [LOCALIZATION_KEYS.NAV_LINKS.EXPLORE]: "अन्वेषण",
    [LOCALIZATION_KEYS.NAV_LINKS.FIND_PROS]: "प्रोफेस खोजें",
    [LOCALIZATION_KEYS.NAV_LINKS.HOW_IT_WORKS]: "कैसे काम करता है",
    [LOCALIZATION_KEYS.NAV_LINKS.PRICING]: "मूल्य निर्धारण",
    [LOCALIZATION_KEYS.NAV_LINKS.BLOG]: "ब्लॉग",
    [LOCALIZATION_KEYS.NAV_LINKS.LOGIN]: "लॉगिन",
    [LOCALIZATION_KEYS.NAV_LINKS.SIGN_UP]: "साइन अप",
    [LOCALIZATION_KEYS.FOOTER.FEATURES]: "फीचर्स",
    [LOCALIZATION_KEYS.FOOTER.FIND_SUBCONTRACTORS]: "उपकरण खोजें",
    [LOCALIZATION_KEYS.FOOTER.FIND_PROJECTS]: "प्रोजेक्ट खोजें",
    [LOCALIZATION_KEYS.FOOTER.ROI_CALCULATOR]: "रिटर्न ऑन इनवेस्टमेंट कैलकुलेटर",
    [LOCALIZATION_KEYS.FOOTER.LEARN]: "जानें",
    [LOCALIZATION_KEYS.FOOTER.FEATURE_GOES_HERE]: "फीचर यहाँ जाता है",
    [LOCALIZATION_KEYS.FOOTER.PROPERTY_TYPES]: "संपत्ति प्रकार",
    [LOCALIZATION_KEYS.FOOTER.RESIDENTIAL_PROPERTY]: "आवासीय संपत्ति",
    [LOCALIZATION_KEYS.FOOTER.COMMERCIAL_PROPERTY]: "व्यापारिक संपत्ति",
    [LOCALIZATION_KEYS.FOOTER.SOCIETY_PROPERTY]: "समुदाय संपत्ति",
    [LOCALIZATION_KEYS.FOOTER.OFFICE_PROPERTY]: "कार्यालय संपत्ति",
    [LOCALIZATION_KEYS.FOOTER.STORE_PROPERTY]: "गार्डन संपत्ति",
    [LOCALIZATION_KEYS.FOOTER.RESOURCES]: "संसाधन",
    [LOCALIZATION_KEYS.FOOTER.EXPLORE]: "अन्वेषण",
    [LOCALIZATION_KEYS.FOOTER.HOW_IT_WORKS]: "कैसे काम करता है",
    [LOCALIZATION_KEYS.FOOTER.PRICING]: "मूल्य निर्धारण",
    [LOCALIZATION_KEYS.FOOTER.BLOG]: "ब्लॉग",
    [LOCALIZATION_KEYS.FOOTER.FAQ]: "अक्सर पूछे जाने वाले प्रश्न",
    [LOCALIZATION_KEYS.FOOTER.QUICK_LINKS]: "त्वरित लिंक",
    [LOCALIZATION_KEYS.FOOTER.SIGN_UP]: "साइन अप",
    [LOCALIZATION_KEYS.FOOTER.LOGIN]: "लॉगिन",
    [LOCALIZATION_KEYS.FOOTER.TERMS_AND_CONDITION]: "शर्तें और शर्तें",
    [LOCALIZATION_KEYS.FOOTER.PRIVACY_POLICY]: "गोपनीयता नीति",
  },
};

export default HindiTranslations;
