import { Input } from "@/components/ui/input";
import { InputProps } from "./Input";
import { useState } from "react";

function TextInput(props: InputProps) {
  const [value, setValue] = useState<string>("");
  return (
    <Input
      type="text"
      placeholder={props.placeholder || "Start typing..."}
      className="w-full px-4 py-2 border-2 border-green-300 shadow-lg rounded-md focus:outline-none"
      onChange={(e) => {
        setValue(e.target.value);
        props.onSubmit(e.target.value);
      }}
      onKeyDown={(e) => {
        console.log("keydown event", e);
        if (e.key === "Enter") {
          // e.preventDefault(); // Prevent form submission if inside a form
          if (props?.proceed) props.proceed(value);
        }
      }}
    />
  );
}

export default TextInput;
