// {
//   "_id": string;
//   "question": string;
//   "inputType": number;
//   "sequence": number;
//   "key": string;
//   "placeholder": string;
// }
export interface IQuestionnaire {
  _id?: string;
  title?: string;
  question: string;
  inputType: INPUT_TYPE;
  placeholder?: string;
  backEndKey: string;
  sequence: number;
  nextStep: number;
  answer?: string | string[];
}

export enum INPUT_TYPE {
  TEXT = 1,
  SINGLE_SELECT = 2,
  MULTI_SELECT = 3,
  PHONE_NUMBER = 4,
  NUMBER = 5,
}
