import React, { useEffect } from "react";
import { useLoading } from "@/contexts/LoadingContext";
// import { GridLoader } from "react-spinners"; // Or any other spinner component
import Lottie, { LottieOptions } from "lottie-react";
import loaderAnimatoin from "@/assets/animations/loader_animation.json";

const Loader: React.FC = () => {
  const { isLoading } = useLoading();

  const options: LottieOptions = {
    loop: true, // Set to false if you want the animation to play once
    autoplay: true, // Start the animation automatically
    animationData: loaderAnimatoin,
    style: {
      height: "100px",
      width: "100px",
    },
  };

  useEffect(() => {
    console.log("is laoding", isLoading);
  }, [isLoading]);

  return isLoading ? (
    <div
      className="w-full h-full"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 9999,
      }}
    >
      {/* <GridLoader color="#28af4a" size={20} /> */}
      {/* <div className="size-30"> */}
      <Lottie {...options} />
      {/* </div> */}
    </div>
  ) : null;
};

export default Loader;
