import { LOCALIZATION_KEYS } from "./keys";

const EnglishTranslations = {
  translation: {
    [LOCALIZATION_KEYS.SCREENS.LOGIN_SCREEN]: "Login Screen",
    [LOCALIZATION_KEYS.SCREENS.HOME_SCREEN]: "Home Screen",
    [LOCALIZATION_KEYS.SCREENS.EXPLORE_SCREEN]: "Explore Screen",
    [LOCALIZATION_KEYS.SCREENS.CART_SCREEN]: "Cart Screen",
    [LOCALIZATION_KEYS.SCREENS.ACCOUNT_SCREEN]: "Account Screen",
    [LOCALIZATION_KEYS.BUTTON_TEXTS.MOVE_TO_HOME]: "Move to home screen",
    [LOCALIZATION_KEYS.LOGIN_WITH_GOOGLE]: "Login With Google",
    [LOCALIZATION_KEYS.LOGIN_WITH_FB]: "Login With Facebook",
    [LOCALIZATION_KEYS.LOGIN_WITH_APPLE]: "Login With Apple",
    [LOCALIZATION_KEYS.APP_AUTHOR]: "BuildMapper",
    [LOCALIZATION_KEYS.NAV_LINKS.EXPLORE]: "Explore",
    [LOCALIZATION_KEYS.NAV_LINKS.FIND_PROS]: "Find Pros",
    [LOCALIZATION_KEYS.NAV_LINKS.HOW_IT_WORKS]: "How it works",
    [LOCALIZATION_KEYS.NAV_LINKS.PRICING]: "Pricing",
    [LOCALIZATION_KEYS.NAV_LINKS.BLOG]: "Blog",
    [LOCALIZATION_KEYS.NAV_LINKS.LOGIN]: "Login",
    [LOCALIZATION_KEYS.NAV_LINKS.SIGN_UP]: "Sign Up",
    [LOCALIZATION_KEYS.FOOTER.FEATURES]: "Features",
    [LOCALIZATION_KEYS.FOOTER.FIND_SUBCONTRACTORS]: "Find Subcontractors",
    [LOCALIZATION_KEYS.FOOTER.FIND_PROJECTS]: "Find Projects",
    [LOCALIZATION_KEYS.FOOTER.ROI_CALCULATOR]: "ROI Calculator",
    [LOCALIZATION_KEYS.FOOTER.LEARN]: "Learn",
    [LOCALIZATION_KEYS.FOOTER.FEATURE_GOES_HERE]: "Feature goes here",
    [LOCALIZATION_KEYS.FOOTER.PROPERTY_TYPES]: "Property Types",
    [LOCALIZATION_KEYS.FOOTER.RESIDENTIAL_PROPERTY]: "Residential Property",
    [LOCALIZATION_KEYS.FOOTER.COMMERCIAL_PROPERTY]: "Commercial Property",
    [LOCALIZATION_KEYS.FOOTER.SOCIETY_PROPERTY]: "Society Property",
    [LOCALIZATION_KEYS.FOOTER.OFFICE_PROPERTY]: "Office Property",
    [LOCALIZATION_KEYS.FOOTER.STORE_PROPERTY]: "Store Property",
    [LOCALIZATION_KEYS.FOOTER.RESOURCES]: "Resources",
    [LOCALIZATION_KEYS.FOOTER.EXPLORE]: "Explore",
    [LOCALIZATION_KEYS.FOOTER.HOW_IT_WORKS]: "How it works",
    [LOCALIZATION_KEYS.FOOTER.PRICING]: "Pricing",
    [LOCALIZATION_KEYS.FOOTER.BLOG]: "Blog",
    [LOCALIZATION_KEYS.FOOTER.FAQ]: "FAQ",
    [LOCALIZATION_KEYS.FOOTER.QUICK_LINKS]: "Quick Links",
    [LOCALIZATION_KEYS.FOOTER.SIGN_UP]: "Sign Up",
    [LOCALIZATION_KEYS.FOOTER.LOGIN]: "Login",
    [LOCALIZATION_KEYS.FOOTER.TERMS_AND_CONDITION]: "Terms and Condition",
    [LOCALIZATION_KEYS.FOOTER.PRIVACY_POLICY]: "Privacy Policy",
    [LOCALIZATION_KEYS.APP_AUTHOR_RIGHTS]: "All rights reserved.",
  },
};

export default EnglishTranslations;
