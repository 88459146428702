"use client";
import React from "react";
import featureImage from "@/assets/home/mobile.webp";
import { TypographyH3 } from "@/components/typography/TypographyH3";
import { TypographyP } from "@/components/typography/TypographyP";
import { motion } from "framer-motion";
import { HeroHighlight, Highlight } from "@/components/ui/hero-highlight";
import SlideIn from "@/components/animations/slide-in";
function SalesOverview() {
  return (
    <>
      <article className="grid grid-cols-1 md:grid-cols-2 gap-9">
        <article className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <TypographyH3 className="text-bmpGreen-400 text-start">
              INCREASE SALES
            </TypographyH3>
            <SalesDescriptionHighlight />

            <TypographyP className="font-normal text-start mt-8 leading-[36px]">
              You’re great at what you do. Now it’s time to win more jobs and
              boost your revenue without the headaches. BuildMapper equips
              contractors and sales people with everything they need to track
              projects, close deals, and grow their businesses – all in one
              easy-to-use platform.
            </TypographyP>
          </div>
        </article>
        <div className="flex justify-end">
          <SlideIn>
            <figure>
              <img
                src={featureImage}
                alt="feature-image"
                aria-label="feature-image"
                className="w-[610px] h-[480px] object-contain"
              />
            </figure>
          </SlideIn>
        </div>
      </article>
    </>
  );
}

function SalesDescriptionHighlight() {
  return (
    <HeroHighlight containerClassName="size-fit">
      <motion.h2
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={{
          opacity: 1,
          y: [20, -5, 0],
        }}
        transition={{
          duration: 0.3,
          ease: [0.4, 0.0, 0.2, 1],
        }}
        className="text-xl md:text-2xl lg:text-3xl text-start font-medium text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-relaxed"
      >
        Sales Made Simple for
        <Highlight className="text-black dark:text-white">
          Subcontractors
        </Highlight>
        and
        <Highlight>Building Suppliers</Highlight>
      </motion.h2>
    </HeroHighlight>
  );
}

export default React.memo(SalesOverview);
