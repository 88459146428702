// SignUp.tsx
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { TypographyP } from "@/components/typography/TypographyP";
import { Label } from "@/components/ui/label";
import fbIcon from "@/assets/icons/social/fb.svg";
import googleIcon from "@/assets/icons/social/google.svg";
import appleIcon from "@/assets/icons/social/apple.svg";
import { useDispatch } from "react-redux";
import { setUser, signUpUser, toggleQuestion } from "./UserSlice";
import { AppDispatch } from "@/store";
import GradientButton from "@/components/ui/gradient-button";
import { useLoading } from "@/contexts/LoadingContext";
import { PasswordInput } from "@/components/ui/password-input";
import { User } from "./types";

// Define the structure of form data
interface SignUpFormInputs {
  email: string;
  password: string;
}

// SignUp component: Handles the form structure and logic
const SignUp: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormInputs>();

  const dispatch = useDispatch<AppDispatch>();
  const { setLoading } = useLoading();

  // Submit handler for form
  const onSubmit: SubmitHandler<SignUpFormInputs> = (data) => {
    console.log(data); // You can add your API call here
    const credentials = {
      email: data.email,
      password: data.password,
      role: 1,
    };

    setLoading(true);
    dispatch(signUpUser(credentials))
      .then((result) => {
        console.log("sign up result", result); // You can add your API call here
        const user = result.payload as User;
        if (user) {
          dispatch(setUser(user));
          dispatch(toggleQuestion(user.questionnaireFilled));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const labelStyle = "block text-[14px] leading-[20px] text-black font-medium";
  const inputStyle = (errors: unknown) =>
    `border-[#cbd5e1] ${errors ? "!border-red-600" : ""}`;
  const socialButtonStyle =
    "flex items-center justify-center w-full text-darkGray font-medium border border-darkGray hover:text-darkGray";
  const socialButtonIconStyle = "flex justify-start size-5  mr-2";

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="">
        <TypographyP className="text-[18px] leading-[26px] font-semibold">
          Create your BuildMapper account
        </TypographyP>
      </div>

      {/* Email Input */}
      <div>
        <Label htmlFor="email" className={labelStyle}>
          Email
        </Label>
        <Input
          id="email"
          placeholder="Enter Email Address"
          className={inputStyle(errors.email)}
          type="email"
          {...register("email", {
            required: "Email is required",
          })}
        />
        {errors.email && (
          <p className="text-red-600 text-xs">{errors.email.message}</p>
        )}
      </div>

      {/* Password Input */}
      <div>
        <Label htmlFor="password" className={labelStyle}>
          Create Password
        </Label>
        <PasswordInput
          placeholder="Create Password"
          className={inputStyle(errors.password)}
          autoComplete="off"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
            maxLength: {
              value: 12,
              message: "Password should not exceed 12 characters",
            },
          })}
        />
        {errors.password && (
          <p className="text-red-600 text-xs">{errors.password.message}</p>
        )}
      </div>

      {/* Submit Button */}
      {/* <Button
        type="submit"
        className="
        w-full
        bg-gradient-to-r from-bmpGreen-400 to-bmpGreen-600
        hover:from-[#2cc052] hover:to-[#23a044]
        text-white font-semibold text-[16px]
        transition-all duration-300 ease-in-out
        focus:ring-2 focus:ring-[#28af4a] focus:ring-opacity-50
      "
      >
        Continue
      </Button> */}
      <GradientButton className="w-full">Continue</GradientButton>

      {/* Divider */}
      <div className="flex items-center justify-center my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="px-2 text-sm text-black font-medium">or</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      {/* Social Media Buttons */}
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={fbIcon} alt="Facebook" /> {/* Facebook icon */}
        </span>
        <span className="flex-1 text-center">Continue with Facebook</span>
      </Button>
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={googleIcon} alt="Google" /> {/* Google icon */}
        </span>
        <span className="flex-1 text-center">Continue with Google</span>
      </Button>
      <Button variant="outline" className={socialButtonStyle}>
        <span className={socialButtonIconStyle}>
          <img src={appleIcon} alt="Apple" /> {/* Apple icon */}
        </span>
        <span className="flex-1 text-center">Continue with Apple</span>
      </Button>

      {/* Terms & Conditions */}
      <TypographyP className="text-[14px] leading-[20px] text-black text-center !mt-5">
        By signing up, you accept our{" "}
        <a href="#" className="text-bmpGreen-600 hover:text-bmpGreen-800">
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a href="#" className="text-bmpGreen-600 hover:text-bmpGreen-800">
          Privacy Policy
        </a>
        .
      </TypographyP>

      {/* Login Link */}
      <TypographyP className="text-black text-[14px] leading-[20px] text-center !mt-1">
        Already have an account?{" "}
        <a href="#" className="text-bmpGreen-600 hover:text-bmpGreen-800">
          Login
        </a>
      </TypographyP>
    </form>
  );
};

export default SignUp;
