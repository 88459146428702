import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { InputProps } from "./Input";
import axiosInstance from "@/api/axios";
import { useLoading } from "@/contexts/LoadingContext";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface SelectInputProps extends InputProps {
  options: string[];
  backEndKey: string;
}

const MultiSelectInput = React.memo((props: SelectInputProps) => {
  const [value, setValue] = useState<string[]>([]);
  const [options, setOptions] = useState([]);

  const { setLoading } = useLoading();
  const country = useSelector(
    (state: RootState) => state.rootReducer.userReducer.country
  );

  const getOptions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: "GET",
        url: "/user/api/v1/question/getOptions",
        params: {
          key: props.backEndKey,
          country: country == "CA" ? "Canada" : "US",
        },
      });

      console.log("getOptions response", response.data);
      let option;
      if (props.backEndKey == "city") {
        option = response.data.data.options.map(
          (o: { city: string; state: string }) => {
            return `${o.city}, ${o.state}`;
          }
        );
      } else option = response.data.data.options;
      setOptions(option);
    } finally {
      setLoading(false);
    }
  }, [props, setLoading]);

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <Autocomplete
      id="auto-partners"
      className="z-20 w-full"
      options={options}
      multiple
      disablePortal
      onSelectCapture={(e) => {
        console.log(e);
      }}
      size="small"
      selectOnFocus={true}
      getOptionLabel={(option) => (Array.isArray(option) ? option[0] : option)}
      value={value}
      onChange={(event, newValue) => {
        console.log("change triggered", event, newValue);
        setValue(newValue as string[]);
        props.onSubmit(newValue as string[]);
      }}
      renderInput={(params) => (
        <TextField
          placeholder={props.placeholder || "Start typing..."}
          {...params}
          variant="outlined"
        />
      )}
      onSelect={(event) => {
        // Prevent click event from bubbling to the input
        event.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // e.preventDefault(); // Prevent form submission if inside a form
          if (props.proceed) props.proceed(value);
        }
      }}
    />
  );
});

export default MultiSelectInput;
