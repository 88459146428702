import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";

import { Button } from "@/components/ui/moving-border";
import { TypographyH3 } from "@/components/typography/TypographyH3";
import { TypographyP } from "@/components/typography/TypographyP";
import AllInOnePack from "./all-in-one-pack";
// import { FiBarChart2, FiFileText, FiMail } from "react-icons/fi";
type Plan = {
  title: string;
  description: string;
  price: number;
  billingCycle: string;
  features: { name: string; description: string }[];
};

const plans: Plan[] = [
  {
    title: "Sales Pro",
    description:
      "For businesses that need to power their pipeline with up-to-date project data and a streamlined CRM.",
    price: 295,
    billingCycle: "month per seat",
    features: [
      {
        name: "Project & Builder Data",
        description: "Up-to-date project data and streamlined CRM.",
      },
      {
        name: "CRM",
        description: "Manage every lead, project, and conversation with ease.",
      },
      {
        name: "Analytics Dashboard",
        description:
          "Track sales performance and identify areas for improvement.",
      },
    ],
  },
  {
    title: "Documents Suite",
    description:
      "Handle your entire sales paperwork process efficiently and professionally.",
    price: 195,
    billingCycle: "month per seat",
    features: [
      {
        name: "Quotes",
        description: "Generate and send professional quotes quickly.",
      },
      {
        name: "Contracts",
        description: "Customize and manage contracts with minimal errors.",
      },
      {
        name: "Invoices",
        description: "Track and manage invoices to get paid faster.",
      },
    ],
  },
  {
    title: "Marketing Hub",
    description:
      "Grow your business with automated marketing tools designed for contractors.",
    price: 250,
    billingCycle: "month per seat",
    features: [
      {
        name: "Website Builder",
        description: "Create a professional site to showcase your work.",
      },
      {
        name: "Email Marketing",
        description: "Automate and personalize your email campaigns.",
      },
      {
        name: "Marketing Automation",
        description: "Automate follow-ups with prospects effortlessly.",
      },
    ],
  },
];

function yearlyPrice(amount: number): number {
  const discount = amount * 0.2; // Calculate 20% of the amount
  return amount - discount; // Subtract the 20% from the original amount
}

const PricingPlans: React.FC<{ yearly: boolean }> = ({ yearly }) => {
  return (
    <section className="flex flex-col gap-2">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {plans.map((plan) => (
          <Card
            key={plan.title}
            className="w-full max-w-md border rounded-lg shadow-lg"
          >
            <CardHeader className="text-start">
              <CardTitle className="text-green-600">{plan.title}</CardTitle>

              <div className="text-charcoal text-[14px] mt-3 font-normal leading-[22px] h-[80px]">
                {plan.description}
              </div>

              <CardDescription className="text-3xl font-bold text-charcoal">
                ${!yearly ? plan.price : yearlyPrice(plan.price)}
                <span className="text-base font-normal">
                  {" "}
                  / {plan.billingCycle}
                </span>
              </CardDescription>
              {!yearly && (
                <p className="text-sm text-gray-500">billed annually</p>
              )}
            </CardHeader>
            <CardContent className="h-[230px]">
              {plan.features.map((feature) => (
                <div key={feature.name} className="mb-4 text-start">
                  <TypographyH3 className="font-semibold !text-[17px] ">
                    {feature.name}
                  </TypographyH3>
                  <TypographyP className="!text-[14px] font-medium text-gray-500">
                    {feature.description}
                  </TypographyP>
                </div>
              ))}
            </CardContent>
            <CardFooter>
              <Button
                borderRadius="1.75rem"
                className=" bg-bmpGreen-100 text-[17px] text-primary font-semibold dark:text-white border-neutral-200"
                containerClassName="w-full"
              >
                Start 7 Day Free Trial
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
      <AllInOnePack />
    </section>
  );
};

export default PricingPlans;
