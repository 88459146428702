import * as React from "react";
import { cn } from "@/lib/utils";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5"; // Importing icons for visibility toggle
import { Input, InputProps } from "./input"; // Assuming Input is in the same directory

// PasswordInput component
const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
      <div className="relative w-full">
        <Input
          type={showPassword ? "text" : "password"}
          className={cn(
            "pr-10", // Add right padding for the eye icon
            className
          )}
          ref={ref}
          {...props}
        />
        <div
          className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <IoEyeOffOutline className="h-5 w-5 text-gray-500" />
          ) : (
            <IoEyeOutline className="h-5 w-5 text-gray-500" />
          )}
        </div>
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
