import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "@/api/axios";
import { ProjectLead as Lead, Project } from "../project-listing/types";

export type MapStyle = "automatic" | "satellite" | "street" | "outdoor";

export type Marker = Lead[] | null;

const initialMap = {
  mapState: {
    longitude: -119.35,
    latitude: 50.23,
    zoom: 10,
    pitch: 30,
    bearing: 50,
  },
  mapStyle: "automatic" as MapStyle,
  selectedMarker: null as Marker,
  projects: [] as Array<Project>,
  leads: [] as Array<Lead>,
};

export const getProjectLeads = createAsyncThunk(
  "project_map/getProjectLeads",
  async ({ params, body }: { params: object; body: object }) => {
    console.log("get project leads", params, body);

    try {
      const response = await axiosInstance({
        method: "POST",
        url: "/odoo/api/v1/projectLeads/fetch",
        params: params,
        data: body,
      });

      console.log("response", response);

      const data = response.data.data as {
        projects: Project[];
        independentLeads: Lead[];
      };

      // leads = leads.map((l) => {
      //   return { ...l, image_url: getImage() };
      // });

      console.log("leads data", data);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  }
);

export const saveProjectLead = createAsyncThunk(
  "project_map/saveProjectLead",
  async (data: object) => {
    // console.log("get project leads", params, data);
    try {
      const response = await axiosInstance({
        method: "POST",
        url: "/odoo/api/v1/projectLeads/save",
        // params: params,
        // data: data,
        data: data,
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.log("err", err);
    }
  }
);

// export const removeProjectLead = createAsyncThunk(
//   "project_map/removeProjectLead",
//   async (data: object) => {
//     // console.log("get project leads", params, data);
//     try {
//       const response = await axiosInstance({
//         method: "POST",
//         url: "/odoo/api/v1/crm/leads/delete",
//         // params: params,
//         // data: data,
//         data: data,
//       });

//       console.log("response", response);

//       return response;
//     } catch (err) {
//       console.log("err", err);
//     }
//   }
// );

export const setFlagLead = createAsyncThunk(
  "project_map/removeProjectLead",
  async (data: object) => {
    // console.log("get project leads", params, data);
    try {
      const response = await axiosInstance({
        method: "PUT",
        url: "/odoo/api/v1/projectLeads/flags",
        // params: params,
        // data: data,
        data: data,
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.log("err", err);
    }
  }
);

const mapSlice = createSlice({
  name: "project_map",
  initialState: initialMap,
  reducers: {
    setMapState: (state, action) => {
      state.mapState = { ...state.mapState, ...action.payload };
    },
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectLeads.fulfilled, (state, action) => {
      // if (action.payload && action.payload?.projects) {
      // console.log(
      //   "inside builder",
      //   action.payload?.projects,
      //   action.payload?.independentLeads
      // );
      state.projects = action.payload?.projects as Project[];
      state.leads = action.payload?.independentLeads as Lead[];
      if (action.payload) {
        const p1 =
          action.payload.projects[0] || action.payload.independentLeads[0];
        const lat = parseFloat(Number(p1.latitude).toFixed(2));
        const lng = parseFloat(Number(p1.longitude).toFixed(2));
        const cords = { latitude: lat, longitude: lng };
        state.mapState = { ...state.mapState, ...cords };
      }
      // }
    });
  },
});

export const { setMapState, setMapStyle, setSelectedMarker } = mapSlice.actions;
export default mapSlice.reducer;
