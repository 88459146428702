import React, { useState } from "react";
import { Button, ButtonProps } from "./button";

interface GradientButtonProps extends ButtonProps {
  children: React.ReactNode;
  className?: string;
}
// const Button = React.forwardRef<HTMLButtonElement, ButtonProps>

const GradientButton = React.forwardRef<HTMLButtonElement, GradientButtonProps>(
  ({ children, className, size = "default", ...props }, ref) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Button
        size={size}
        className={`
        relative overflow-hidden px-6 py-3 font-semibold text-white text-[16px] shadow-lg transition-all duration-300 ease-in-out
        ${isHovered ? "shadow-xl" : "shadow-md"}
      ${className}`}
        style={{
          background: `linear-gradient(45deg, #28af4a, #34d058, #28af4a)`,
          backgroundSize: "200% 200%",
          animation: isHovered ? "gradientShift 3s ease infinite" : "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...props}
        ref={ref}
      >
        <span className="relative z-10 flex items-center">{children}</span>
        <span
          className="absolute inset-0 opacity-0 transition-opacity duration-300 ease-in-out"
          style={{
            background:
              "radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%)",
            opacity: isHovered ? 0.5 : 0,
          }}
        />
      </Button>
    );
  }
);

export default GradientButton;
