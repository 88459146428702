"use client";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

interface SlideInProps {
  duration?: number;
  children: React.ReactNode;
}

const SlideIn: React.FC<SlideInProps> = ({ duration = 1, children }) => {
  const variants = {
    hidden: { x: "-100%", opacity: 0 }, // Start off-screen to the left
    visible: { x: 0, opacity: 1 }, // Slide in to original position
  };

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it becomes visible
        }
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect(); // Cleanup on unmount
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: duration }} // Adjust duration as needed >
    >
      {children}
    </motion.div>
  );
};

export default SlideIn;
